// React Basic and Bootstrap
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
//import Json Data
import ResearchJson from "../Pages/Research/Research.json";

class Research extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      researchExperiments: ResearchJson,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    const { activeTab, researchExperiments } = this.state;

    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row className="align-items-end mb-4 pb-4">
              <SectionTitle
                title="Research & Experiments"
                desc="Exploring the frontier of technology to innovate and solve tomorrow's challenges today. Our continuous research and experiments drive cutting-edge solutions for your success."
              />
            </Row>
            <Row>
              <Col md={4} className="mt-4 pt-2">
                <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
                  {researchExperiments.map((experiment) => (
                    <NavItem key={experiment.id} className="mt-2">
                      <NavLink
                        to="#"
                        className={classnames(
                          { active: activeTab === experiment.id },
                          "rounded",
                        )}
                        onClick={() => this.toggle(experiment.id)}
                      >
                        <div className="text-center py-1">
                          <h6 className="mb-0">{experiment.title}</h6>
                        </div>
                      </NavLink>
                    </NavItem>
                  ))}
                </ul>
              </Col>

              <Col md={8} xs={12} className="mt-4 pt-2">
                <TabContent activeTab={activeTab}>
                  {researchExperiments.map((experiment) => (
                    <TabPane
                      key={experiment.id}
                      className="fade bg-white show p-4 rounded shadow"
                      tabId={experiment.id}
                    >
                      <img
                        src={require(
                          `../../assets/images/experiments/${experiment.image}`,
                        )}
                        className="img-fluid rounded shadow"
                        alt=""
                      />
                      <div className="mt-4">
                        <p className="text-muted">{experiment.description}</p>
                        <Link to={experiment.link} className="text-primary">
                          See More{" "}
                          <i className="uil uil-angle-right-b align-middle"></i>
                        </Link>
                      </div>
                    </TabPane>
                  ))}
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Research;
