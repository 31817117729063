import React, { Component } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

//import Json Data
import ResearchJson from "./Research.json";

export default class ResearchDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null,
      researchExperiments: ResearchJson,
    };
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);

    // JSON data defined here
    const blogs = ResearchJson;

    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const blogId = queryParams.get("id");

    if (blogId) {
      console.log(blogId, "blogId");

      // Find the corresponding blog from your data
      const blog = blogs.find((blog) => blog.id === blogId);
      console.log(blog, "blog");

      if (blog) {
        this.setState({ blog });
      }
    }
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document
          .querySelector(".settingbtn")
          ?.classList.add("btn-soft-primary");
      }
    }
  };
  render() {
    const { blog } = this.state;

    if (!blog) {
      return <p>Loading...</p>; // Show loading or error message if blog not found
    }
    return (
      <React.Fragment>
        <section className="bg-half">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={10}>
                <div className="section-title">
                  <div className="text-center">
                    <h4 className="title mb-4">{blog.title}</h4>
                    <img
                      src={require(
                        `../../../assets/images/experiments/${blog.image}`,
                      )}
                      className="img-fluid rounded-md shadow-md mb-2"
                      alt="#"
                    />
                    <p className=" mb-0 mt-4">{blog.description}</p>
                  </div>
                  {/* Render content dynamically */}
                  {blog.content.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.title && <h5 className="pt-4">{item.title}</h5>}
                        <p className=" mb-0 mt-4">{item.text}</p>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="justify-content-center">
              <Col xs={12} className="text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-3">Read More</h4>
                </div>
              </Col>
            </Row>

            <Row>
              {this.state.researchExperiments &&
                this.state.researchExperiments
                  .filter((blogs) => blogs.id !== blog.id)
                  .slice(0, 3) // Limit the number of blogs to show
                  .map((blog1, key) => (
                    <Col lg={4} md={6} className="mt-4 pt-2" key={key}>
                      <Card
                        className=" border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden"
                        // style={{ height: "38rem" }}
                      >
                        <img
                          src={require(
                            `../../../assets/images/experiments/${blog1.image}`,
                          )}
                          className="img-fluid"
                          style={{ height: "18rem", width: "30rem" }}
                          alt="work"
                        />
                        <CardBody>
                          <div className="content">
                            <h5 className="mt-3">
                              <h5 className="text-dark title">
                                <a
                                  href={`/research-details?id=${blog1.id}`}
                                  className="text-dark"
                                  onClick={() => window.location.reload()}
                                >
                                  {blog1.title}
                                </a>
                              </h5>
                            </h5>

                            <a
                              href={`/research-details?id=${blog1.id}`}
                              className="text-primary readmore"
                              onClick={() => window.location.reload()}
                            >
                              Read More
                              <i className="uil uil-angle-right-b align-middle"></i>
                            </a>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
