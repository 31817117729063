/* eslint-disable no-unused-vars */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Typist from "react-typist";
import "../Pages/common.css";
// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// Import images
import busi01 from "../../assets/images/busi01.jpg";
import bgImg from "../../assets/images/work/Grid.png";
import Dot from "../../assets/images/work/Grid1.png";
import Movie from "../../assets/images/work/Movie.gif";
import video1 from "../../assets/images/work/video1.gif";
import video2 from "../../assets/images/work/video2.gif";
import video3 from "../../assets/images/work/video3.gif";
import heroimage from "../../assets/images/hero/hero_image-1.png";

class Section extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpen: false,
  //   };
  //   this.openModal = this.openModal.bind(this);
  // }

  // openModal() {
  //   this.setState({ isOpen: true });
  // }

  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
    };
    // Bind the method to this component instance
    this.toggleVideo = this.toggleVideo.bind(this);
  }

  toggleVideo() {
    this.setState({ showVideo: !this.state.showVideo });
  }

  render() {
    return (
      <React.Fragment>
        {/* Hero Start */}
        <section
          className="bg-half-170 border-bottom d-table w-100"
          id="home"
          style={{
            background: `url(${Dot}) center center`,
            backgroundSize: "cover",
          }}
        >
          <Container>
            <Row className="align-items-center" style={{ height: "100%" }}>
              <Col lg={6} md={7}>
                <div className="title-heading mt-4">
                  <h1 className="heading mb-3">
                    Pioneering the Future of Innovation
                  </h1>
                  <p className="para-desc text-muted">
                    Unleash the power of innovation with our elite team of
                    in-house experts. We drive success by transforming bold
                    ideas into breakthrough solutions. Join us and redefine
                    what's possible in 2024.
                  </p>
                  <div className="mt-4">
                    <Link
                      to="/contact"
                      className="btn btn-outline-primary rounded"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                lg={6}
                md={5}
                className="d-flex align-items-center"
                style={{ height: "100%" }}
              >
                  <Row className="w-100" style={{ height: "100%" }}>
                    <img
                        src={heroimage}
                        className="img-fluid"
                        alt=""
                        style={{ maxWidth: "100%", height: "100%" }}
                    />
                  </Row>
              </Col>
            </Row>
            {/* <ModalVideo
              channel="vimeo"
              isOpen={this.state.isOpen}
              videoId="287684225"
              onClose={() => this.setState({ isOpen: false })}
            /> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
