import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";

//Import Images
import about2 from "../../../assets/images/company/about2.png";
import WorkProcess from "../../../components/Shared/WorkProcess";

import airBills from "../../../assets/images/our client/airbills.png";
import billtrim from "../../../assets/images/our client/billtrim.png";
import botsupply from "../../../assets/images/our client/botsupply.png";
import ekhoo from "../../../assets/images/our client/ekhoo.png";
import jatana from "../../../assets/images/our client/jatana.png";
import neuralspace from "../../../assets/images/our client/neuralspace.png";
import viralget from "../../../assets/images/our client/viralGet.png";
import moxci from "../../../assets/images/our client/moxci.png";
import ExperienceTeam from "./ExperienceTeam";
import Research from "../../Home/Research";
import Clients from "../../Home/Client";

export default class PageAboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      partners: [
        {
          id: 1,
          img: botsupply,
        },
        {
          id: 2,
          img: moxci,
        },
        {
          id: 3,
          img: billtrim,
        },
        {
          id: 4,
          img: jatana,
        },
        {
          id: 5,
          img: ekhoo,
        },
        // {
        //   id: 6,
        //   img: viralget,
        // },
        // {
        //   id: 7,
        //   img: neuralspace,
        // },
        {
          id: 8,
          img: airBills,
        },
      ],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light");
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu")?.classList.add("nav-light");
    });

    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation() {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  }
  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row className="align-items-center" id="counter">
              <Col md={6}>
                <img src={about2} className="img-fluid" alt="" />
              </Col>

              <Col md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4">
                  <div className="d-flex mb-4">
                    <span className="text-primary h1 mb-0 item-center">
                      <span className="counter-value display-1 fw-bold">
                        <CountUp start={10} end={10} />
                      </span>
                    </span>
                    <span className="text-primary h1 mb-0 item-center align-self-center">
                      <span className="counter-value fw-bold">+</span>
                    </span>

                    <span className="h4 mb-0 item-center align-self-center ms-2">
                      <span className="counter-value fw-bold">
                        Average Years Experience
                      </span>
                    </span>
                  </div>
                  <div className="section-title">
                    <h4 className="title mb-4">Our Core</h4>
                    <p className="text-muted">
                      We are a team of engineers and researchers passionate
                      about solving complex problems with creative solutions. We
                      work as an in-house team, ensuring complete transparency
                      and collaboration, which minimizes time and effort to help
                      you achieve an early product-market fit.
                    </p>
                    <Link to="/contact" className="btn btn-primary mt-3">
                      Contact us
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Clients />
        </section>
        <section className="section bg-light">
          {/* Work process render */}
          <WorkProcess />

          <Research />
        </section>
        <ExperienceTeam />
      </React.Fragment>
    );
  }
}
