import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
//import Images

import work14 from "../../assets/images/work/bs-o.jpeg";
import work15 from "../../assets/images/work/ev-b.jpeg";
import work16 from "../../assets/images/work/bt-5.jpeg";
import work17 from "../../assets/images/work/mx.jpeg";

const Partners = () => {
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="mb-4">Startup Stories</h4>
                <p className="para-desc mx-auto text-muted">
                  Execution stories of startups that have grown with us.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={6} md={6} xs={12} className="mt-4 pt-2">
              <Card className="shadow rounded border-0 overflow-hidden">
                <Row className="row g-0">
                  <Col md={5}>
                    <CardBody>
                      <h5 className="card-title">BotSupply APS</h5>
                      <p className="card-text text-muted">
                        An ORACLE Partner company, won several awards for their
                        innovations in conversational AI.
                      </p>
                      <Link
                        to="/startup-stories?id=1"
                        className="mt-3 text-primary"
                      >
                        <small>See More</small>
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </CardBody>
                  </Col>
                  <Col md={7}>
                    <img
                      src={work14}
                      className="img-fluid"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={6} md={6} xs={12} className="mt-4 pt-2">
              <Card className="shadow rounded border-0 overflow-hidden">
                <Row className="row g-0">
                  <Col md={5}>
                    <CardBody>
                      <h5 className="card-title">Moxci Premiers</h5>
                      <p className="card-text text-muted">
                        A movie distribution company aiming to revolutionize the
                        industry.
                      </p>
                      <Link
                        to="/startup-stories?id=2"
                        className="mt-3 text-primary"
                      >
                        <small>See More</small>
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </CardBody>
                  </Col>
                  <Col md={7}>
                    <img
                      src={work17}
                      className="img-fluid"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={6} md={6} xs={12} className="mt-4 pt-2">
              <Card className="shadow rounded border-0 overflow-hidden">
                <Row className="row g-0">
                  <Col md={5}>
                    <CardBody>
                      <h5 className="card-title">BillTrim INC</h5>
                      <p className="card-text text-muted">
                        FinTech Platform for automated bill payments. Raised
                        $1.5M in seed funding.
                      </p>
                      <Link
                        to="/startup-stories?id=3"
                        className="mt-3 text-primary"
                      >
                        <small>See More</small>
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </CardBody>
                  </Col>
                  <Col md={7}>
                    <img
                      src={work16}
                      className="img-fluid"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={6} md={6} xs={12} className="mt-4 pt-2">
              <Card className="shadow rounded border-0 overflow-hidden">
                <Row className="row g-0">
                  <Col md={5}>
                    <CardBody>
                      <h5 className="card-title">Envi EV</h5>
                      <p className="card-text text-muted">
                        Electric Vehicle startup that raised $5M in seed
                        funding.
                      </p>
                      <Link
                        to="/startup-stories?id=4"
                        className="mt-3 text-primary"
                      >
                        <small>See More</small>
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </CardBody>
                  </Col>
                  <Col md={7}>
                    <img
                      src={work15}
                      className="img-fluid"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5 pb-5 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="mb-4">Free 1-Hour Consultation</h4>
                <p className="para-desc mx-auto text-muted">
                  Get expert advice and see how we can help your startup grow.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center">
                <Link
                  to="https://calendly.com/ashish-5863/"
                  target="_black"
                  className="btn btn-outline-primary rounded"
                >
                  <i className="uil uil-envelope-check"></i> Book Now
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Partners;
