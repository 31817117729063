import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
//Fade in effect
import FadeIn from "react-fade-in";

class TermCondition extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-light d-table w-100"
          // style={{ background: `url(${bgImg}) center center` }}
        >
          {/* <div className="bg-overlay"></div> */}
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title title-dark text-primary mb-0">
                    Terms and Service
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section">
          <Container>
            <Row className=" justify-content-center">
              <div>
                <h4 className="">Introduction</h4>
                <p className="text-muted mb-0">
                  These Website Standard Terms and Conditions written on this
                  webpage shall manage your use of our website, IAastha Research
                  and Consulting accessible at https://iaastha.com.
                </p>
                <p className="text-muted mb-0">
                  These Terms will be applied fully and affect to your use of
                  this Website. By using this Website, you agreed to accept all
                  terms and conditions written in here. You must not use this
                  Website if you disagree with any of these Website Standard
                  Terms and Conditions.
                </p>
                <p className="text-muted mb-0">
                  Minors or people below 18 years old are not allowed to use
                  this Website.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Intellectual Property Rights</h4>
                <p className="text-muted mb-0">
                  Other than the content you own, under these Terms, Ashikha
                  Solutions And Services Private Limited and/or its licensors
                  own all the intellectual property rights and materials
                  contained in this Website.
                </p>
                <p className="text-muted mb-0">
                  You are granted limited license only for purposes of viewing
                  the material contained on this Website.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Restrictions</h4>
                <p className="text-muted mb-0">
                  You are specifically restricted from all of the following:
                </p>
                <ul className="mb-0 mt-4" style={{ listStyleType: "none" }}>
                  <li>publishing any Website material in any other media;</li>
                  <li>
                    selling, sublicensing and/or otherwise commercializing any
                    Website material;
                  </li>
                  <li>
                    publicly performing and/or showing any Website material;
                  </li>
                  <li>
                    using this Website in any way that is or may be damaging to
                    this Website;
                  </li>
                  <li>
                    using this Website in any way that impacts user access to
                    this Website;
                  </li>
                  <li>
                    using this Website contrary to applicable laws and
                    regulations, or in any way may cause harm to the Website, or
                    to any person or business entity;
                  </li>
                  <li>
                    engaging in any data mining, data harvesting, data
                    extracting or any other similar activity in relation to this
                    Website;
                  </li>
                  <li>
                    using this Website to engage in any advertising or
                    marketing.
                  </li>
                </ul>
                <p className="text-muted mb-0">
                  Certain areas of this Website are restricted from being access
                  by you and Ashikha Solutions And Services Private Limited may
                  further restrict access by you to any areas of this Website,
                  at any time, in absolute discretion. Any user ID and password
                  you may have for this Website are confidential and you must
                  maintain confidentiality as well.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Your Content</h4>
                <p className="text-muted mb-0">
                  In these Website Standard Terms and Conditions, "Your Content"
                  shall mean any audio, video text, images or other material you
                  choose to display on this Website. By displaying Your Content,
                  you grant Ashikha Solutions And Services Private Limited a
                  non-exclusive, worldwide irrevocable, sub licensable license
                  to use, reproduce, adapt, publish, translate and distribute it
                  in any and all media.
                </p>
                <p className="text-muted mb-0">
                  Your Content must be your own and must not be invading any
                  third-party’s rights. Ashikha Solutions And Services Private
                  Limited reserves the right to remove any of Your Content from
                  this Website at any time without notice.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Your Privacy</h4>
                <p className="text-muted mb-0">Please read Privacy Policy.</p>
              </div>
              <div>
                <h4 className="mt-4">No warranties</h4>
                <p className="text-muted mb-0">
                  This Website is provided "as is," with all faults, and Ashikha
                  Solutions And Services Private Limited express no
                  representations or warranties, of any kind related to this
                  Website or the materials contained on this Website. Also,
                  nothing contained on this Website shall be interpreted as
                  advising you.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Limitation of liability</h4>
                <p className="text-muted mb-0">
                  In no event shall Ashikha Solutions And Services Private
                  Limited, nor any of its officers, directors and employees,
                  shall be held liable for anything arising out of or in any way
                  connected with your use of this Website whether such liability
                  is under contract. Ashikha Solutions And Services Private
                  Limited, including its officers, directors and employees shall
                  not be held liable for any indirect, consequential or special
                  liability arising out of or in any way related to your use of
                  this Website.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Indemnification</h4>
                <p className="text-muted mb-0">
                  You hereby indemnify to the fullest extent Ashikha Solutions
                  And Services Private Limited from and against any and/or all
                  liabilities, costs, demands, causes of action, damages and
                  expenses arising in any way related to your breach of any of
                  the provisions of these Terms.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Severability</h4>
                <p className="text-muted mb-0">
                  If any provision of these Terms is found to be invalid under
                  any applicable law, such provisions shall be deleted without
                  affecting the remaining provisions herein.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Variation of Terms</h4>
                <p className="text-muted mb-0">
                  Ashikha Solutions And Services Private Limited is permitted to
                  revise these Terms at any time as it sees fit, and by using
                  this Website you are expected to review these Terms on a
                  regular basis.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Assignment</h4>
                <p className="text-muted mb-0">
                  The Ashikha Solutions And Services Private Limited is allowed
                  to assign, transfer, and subcontract its rights and/or
                  obligations under these Terms without any notification.
                  However, you are not allowed to assign, transfer, or
                  subcontract any of your rights and/or obligations under these
                  Terms.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Entire Agreement</h4>
                <p className="text-muted mb-0">
                  These Terms constitute the entire agreement between Ashikha
                  Solutions And Services Private Limited and you in relation to
                  your use of this Website, and supersede all prior agreements
                  and understandings.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Governing Law & Jurisdiction</h4>
                <p className="text-muted mb-0">
                  These Terms will be governed by and interpreted in accordance
                  with the laws of the State of Maharashtra, and you submit to
                  the non-exclusive jurisdiction of the state and federal courts
                  located in in for the resolution of any disputes.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Refund Policy</h4>
                <p className="text-muted mb-0">
                  Please note that there may be certain orders that we are
                  unable to accept and must cancel. We reserve the right, at our
                  sole discretion, to refuse or cancel any order for any reason,
                  without any claims or liability to pay finance charges or
                  interest on the amount. Some situations that may result in
                  your order being canceled include but are not limited to
                  inaccuracies or errors in Solutions or pricing information,
                  technical or technological problems or problems identified in
                  relation to credit / debit fraud. We may also require
                  additional verifications or information before accepting any
                  order. We will contact you if all or any portion of your order
                  is canceled or if additional information is required to accept
                  your order. If your order is cancelled by the Company after
                  your credit / debit card has been charged, the said amount
                  will be refunded to that credit / debit card account. You
                  agree and acknowledge that unless stated otherwise you are not
                  entitled to cancel any orders made by you on this Website.
                </p>
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default TermCondition;
