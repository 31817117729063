import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import ResearchJson from "./Research.json";

class PageBlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      researchExperiments: ResearchJson,
    };
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation() {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document
          .querySelector(".settingbtn")
          ?.classList.add("btn-soft-primary");
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title title-dark text-primary mb-0">
                    Research and Experiments
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section">
          <Container>
            <Row id="grid" className="mt-4 justify-content-center">
              {this.state.researchExperiments.map((data, key) => (
                <Col
                  lg={4}
                  md={12}
                  xs={12}
                  key={key}
                  className="spacing picture-item"
                >
                  <FadeIn delay={100}>
                    <Card className="border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden">
                      <div className="content">
                        <h4
                          className="text-dark mt-2"
                          style={{ paddingLeft: "1.5rem" }}
                        >
                          {data.title}
                        </h4>
                      </div>
                      <img
                        src={require(
                          `../../../assets/images/experiments/${data.image}`,
                        )}
                        className="img-fluid"
                        style={{ height: "18rem", width: "30rem" }}
                        alt="work"
                      />
                      <CardBody>
                        <div className="content">
                          <p className="text-muted">
                            {(() => {
                              const description = data.description || ""; // Default to empty string if undefined
                              return description.length > 84
                                ? description.slice(0, 84) + "..."
                                : description;
                            })()}
                          </p>
                          <Link
                            to={`/research-details?id=${data.id}`}
                            className="link h6"
                          >
                            Read More{" "}
                            <i className="uil uil-angle-right-b align-middle"></i>
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </FadeIn>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default PageBlogList;
